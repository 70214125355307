export const capitalizeWord = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);

const toLoverCase = (str: string) => str.toLowerCase();

const omitLowerCase = (str: string) => str;

const capitalize = (str: string, isToLowercase = true) =>
  str
    .split(' ')
    .map(isToLowercase ? toLoverCase : omitLowerCase)
    .map(capitalizeWord)
    .join(' ');

export default capitalize;
