import getShortPath from '../getShortPath';

const getFullPath = () => {
  const { search } = window.location;
  const pathname = getShortPath();
  
  return `${pathname}${search}`;
};

export default getFullPath;
