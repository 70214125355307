import {AnalyticProvider} from './types';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const load = () => {
  /* tslint:disable */

  const s = document.createElement('script');
  s.async = true;
  s.src = 'https://www.googletagmanager.com/gtag/js?id=G-TLPKT07NG1';
  document.head.appendChild(s);
};

export const init = ({}: AnalyticProvider) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  // @ts-ignore
  gtag('js', new Date());
  // @ts-ignore
  gtag('config', 'G-TLPKT07NG1');
};

// export const getGaHeaders = () => ({ 'anm-mkt-ga': cookie.get('_ga') || '' });

export const trackVisitPage = () => {
  // todo:
  // ga('set', 'page', window.location.href);
  // ga('send', 'pageview');
};

// @ts-ignore
export const addTransaction = (id: string, price: string | number, name: string) => {
  // todo:

  // export const addTransaction = (
  //   id: string,
  //   price: string | number,
  //   name: string
  // ) => {
  //   ga('ecommerce:addTransaction', {
  //     id,
  //     revenue: price
  //   });
  //
  //   ga('ecommerce:addItem', {
  //     id,
  //     name,
  //     price,
  //     quantity: 1
  //   });
  //
  //   ga('ecommerce:send');
  // };
};
