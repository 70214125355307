export const STREAMING_STUDIO_URL_DEV = `http://localhost:8080/live/`;
export const STREAMING_STUDIO_URL_TEST = `https://wave-test.video/live/`;
export const STREAMING_STUDIO_URL_PROD = `https://wave.video/live/`;

export const STREAMING_LIVE_API_URL_TEST = `https://streaming-live.test.stacks.animatron-test.com/`;
export const STREAMING_LIVE_API_URL_PROD = `https://streaming-live.animatron.com/`;

export const STREAMING_ROOM_API_URL_TEST = `https://streaming.test.stacks.animatron-test.com`;
export const STREAMING_ROOM_API_URL_PROD = `https://streaming.animatron.com`;

export const STREAMING_DEBUG_TEST = `https://streaming-live.test.stacks.animatron-test.com/debugging/detail`;
export const STREAMING_DEBUG_PROD = `https://streaming-live.animatron.com/debugging/detail`;

export const MAIN_MEDIA_STREAM_ID = 'main';
export const PREVIEW_MEDIA_STREAM_ID = 'preview';
