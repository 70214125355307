import isServer from '../is/isServer';

type CookieProps = {
  [name: string]: string | boolean | undefined;
  path: string;
  domain: string;
  expires: string;
  secure?: boolean;
  samesite?: string;
};

export const getDomain = () =>
  isLocal() ? location.hostname : location.hostname.replace(/\w\w\w\.|^/, '.');

export const getExpireDate = (days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  return date.toUTCString();
};

const toCookie = (props: CookieProps) =>
  Object.keys(props).reduce((acc, key) => {
    const value = props[key] === true ? '' : `=${props[key]}`;
    return `${acc}${key}${value};`;
  }, '');

export const get = (
  name: string,
  originCookie?: string
): string | undefined => {
  const browserCookie = isServer() ? '' : document.cookie;
  const cookie = `; ${originCookie || browserCookie}`;
  const parts = cookie.split(`; ${name}=`);

  if (parts.length === 2) {
    const value = parts.pop() || '';
    return value
      .replace('=', '')
      .split(';')
      .shift();
  }
};

export const remove = (name: string, domain?: string) => {
  if (isServer()) return;

  document.cookie = toCookie({
    [name]: '',
    path: '/',
    domain: domain || getDomain(),
    expires: 'Thu, 01 Jan 1970 00:00:01 GMT'
  });
};

const isLocal = () => location.hostname === 'localhost';

export const set = (
  name: string,
  value = '',
  expireDays = 10,
  domain?: string
) => {
  if (isServer()) return;

  document.cookie = toCookie({
    [name]: value,
    path: '/',
    domain: domain || getDomain(),
    expires: getExpireDate(expireDays),
    ...((!isLocal() && { SameSite: 'None', Secure: true }) as {})
  });
};
