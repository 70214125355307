type ErrorCb<R> = (e?: Error) => R | null;

export const safeRun = <T, R>(
  unsafeFunc: () => T,
  onError: ErrorCb<R> = () => null
): T | R | null => {
  try {
    return unsafeFunc();
  } catch (e) {
    return onError(e);
  }
};

export const safeParse = (state: string, onError = console.error) =>
  safeRun(() => JSON.parse(state), onError);

export const safeAsyncRun = async <T, R>(
  unsafeFunc: () => Promise<T>,
  onError: ErrorCb<R> = () => null
): Promise<T | R | null> => {
  try {
    return await unsafeFunc();
  } catch (e) {
    return onError(e);
  }
};
