import {
  ANIMATRON_ASSETS_PROD,
  ANIMATRON_LIBRARY_PROD,
  ANIMATRON_PROD,
  WAVE_API_PROD,
  WAVE_PROD,
  YOUTUBE_API_PROD
} from '@anm/constants/domains';
import {
  AFFILIATE_TAP_ID_PROD,
  FACEBOOK_ANALYTIC_WAVE_PROD,
  FACEBOOK_API_KEY_PROD,
  FACEBOOK_AUTH_URL,
  FRONT_CHAT_WAVE_PROD,
  FULLSTORY_ANALYTIC_PROD,
  GOOGLE_ANALYTIC_WAVE_PROD,
  GOOGLE_API_KEY_PROD,
  GOOGLE_AUTH_URL,
  HOTJAR_ANALYTIC,
  MIXPANEL_ANALYTIC_API_HOST,
  MIXPANEL_ANALYTIC_WAVE_PROD,
  TWITTER_AUTH_URL,
  WAVE_BUTTON_API_KEY_PROD,
  WISTIA_API_KEY_PROD,
  WISTIA_AUTH_URL
} from '@anm/constants/services';
import { STREAMING_STUDIO_URL_PROD } from '@anm/constants/streaming';
import getDomainNameFromUrl from '@anm/helpers/get/getDomainNameFromUrl';
import { DeepPartial } from 'helpers';

import { Config } from './types';

const configs: DeepPartial<Config> = {
  port: 3000,
  isProd: true,
  cdnUrl: `${ANIMATRON_ASSETS_PROD}landings/`,
  apiUrl: WAVE_API_PROD,
  waveUrl: WAVE_PROD,
  studioUrl: ANIMATRON_PROD,
  youtubeApiUrl: YOUTUBE_API_PROD,
  streamingStudioUrl: STREAMING_STUDIO_URL_PROD,
  editorUrl: `${WAVE_PROD}editor`,
  calendarData: ANIMATRON_LIBRARY_PROD,
  nextDevMode: false,
  buttonEmbedUrl: `${WAVE_PROD}editor/embed.js`,
  waveButtonApiKey: WAVE_BUTTON_API_KEY_PROD,
  support: {
    intercom: {
      id: FRONT_CHAT_WAVE_PROD,
      enable: true
    }
  },
  services: {
    wistia: {
      apiKey: WISTIA_API_KEY_PROD,
      oauthUrl: WISTIA_AUTH_URL
    },
    google: {
      apiKey: GOOGLE_API_KEY_PROD,
      oauthUrl: GOOGLE_AUTH_URL
    },
    twitter: {
      oauthUrl: TWITTER_AUTH_URL
    },
    facebook: {
      apiKey: FACEBOOK_API_KEY_PROD,
      oauthUrl: FACEBOOK_AUTH_URL
    }
  },
  analytics: {
    google: {
      enable: true,
      id: GOOGLE_ANALYTIC_WAVE_PROD,
      domain: getDomainNameFromUrl(ANIMATRON_PROD)
    },
    facebook: {
      enable: false,
      id: FACEBOOK_ANALYTIC_WAVE_PROD
    },
    mixpanel: {
      enable: false,
      id: MIXPANEL_ANALYTIC_WAVE_PROD,
      apiHost: WAVE_API_PROD + MIXPANEL_ANALYTIC_API_HOST
    },
    fullstory: {
      enable: false,
      id: FULLSTORY_ANALYTIC_PROD
    },
    hotjar: {
      enable: false,
      id: HOTJAR_ANALYTIC
    }
  },
  affiliates: {
    tap: {
      enable: true,
      id: AFFILIATE_TAP_ID_PROD
    }
  },
  embed: {
    analytics: {
      google: {
        enable: false,
        id: GOOGLE_ANALYTIC_WAVE_PROD
      },
      facebook: {
        enable: false,
        id: FACEBOOK_ANALYTIC_WAVE_PROD
      },
      mixpanel: {
        enable: false,
        id: MIXPANEL_ANALYTIC_WAVE_PROD
      },
      fullstory: {
        enable: false,
        id: FULLSTORY_ANALYTIC_PROD
      },
      hotjar: {
        enable: false,
        id: HOTJAR_ANALYTIC
      }
    }
  },
  langs: {
    languages: ['de', 'pt', 'br', 'ca', 'uk', 'es', 'id', 'it', 'fr', 'nl', 'pl', 'ru', 'ua', 'ja', 'zh']
  }
};

export default configs;
