declare global {
  interface Window {
    hj: any;
    _hjSettings: any;
  }
}

export const load = (id: string) => {
  /* tslint:disable */
  (function(h: any, o: any, t: any, j: any, r: any = null) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: id, hjsv: 6 };
    r = o.createElement('script');
    r.defer = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;

    function load() {
      setTimeout(() => document.body.appendChild(r), 1500);
    }
    window.addEventListener('load', load, false);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};
