const isArraysDifference = (firstArr: any[], secondArr: any[]) =>
  firstArr.some((item, index) => secondArr[index] !== item);

const runOnlyDifferentArgs = <T extends (...args: any[]) => void>(
  func: T
): T => {
  let pervArgs: any[] = [];

  return ((...args: any[]) => {
    if (pervArgs.length !== 0) {
      if (!isArraysDifference(pervArgs, args)) return;
    }

    pervArgs = args;
    func(...args);
  }) as T;
};

export default runOnlyDifferentArgs;
