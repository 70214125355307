import { UserSubscriptionDetails } from '@anm/api/modules/user';
import isServer from '@anm/helpers/is/isServer';
import capitalize from '@anm/helpers/string/capitalize';
import waitFor from '@anm/helpers/waitFor';
import { AnimatronProduct } from 'global';
import pickBy from 'lodash/fp/pickBy';
import { User } from 'user';

import getFullPath from '../helpers/getFullPath';
import getPage from '../helpers/getPage';
import getShortPath from '../helpers/getShortPath';

import { UserTrackingProps } from './types';

let currentProduct: AnimatronProduct = 'WAVE';

export const load = (libUrl?: string) => {
  /* tslint:disable */
  window.MIXPANEL_CUSTOM_LIB_URL = libUrl;

  (function(e, a: any) {
    if (!a.__SV) {
      const b = window;
      try {
        let c,
          l,
          i,
          j = b.location,
          g = j.hash;
        c = function(a: any, b: any) {
          return (l = a.match(RegExp(b + '=([^&]*)'))) ? l[1] : null;
        };
        g &&
          c(g, 'state') &&
          ((i = JSON.parse(decodeURIComponent(c(g, 'state')))),
          'mpeditor' === i.action &&
            (b.sessionStorage.setItem('_mpcehash', g),
            history.replaceState(i.desiredHash || '', e.title, j.pathname + j.search)));
      } catch (m) {}
      let k, h;
      window.mixpanel = a;
      a._i = [];
      a.init = function(b: any, c: any, f: any) {
        function e(b: any, a: any) {
          const c = a.split('.');
          2 == c.length && ((b = b[c[0]]), (a = c[1]));
          b[a] = function() {
            b.push([a].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        let d = a;
        'undefined' !== typeof f ? (d = (a[f] as any) = [] as any) : (f = 'mixpanel');
        d.people = d.people || [];
        d.toString = function(b: any) {
          let a = 'mixpanel';
          'mixpanel' !== f && (a += '.' + f);
          b || (a += ' (stub)');
          return a;
        };
        d.people.toString = function() {
          return d.toString(1) + '.people (stub)';
        };
        k = 'disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user'.split(
          ' '
        );
        for (h = 0; h < k.length; h++) e(d, k[h]);
        a._i.push([b, c, f]);
      };
      a.__SV = 1.2;
      const script = e.createElement('script');
      script.type = 'text/javascript';
      script.defer = !0;
      script.src =
        'undefined' !== typeof window.MIXPANEL_CUSTOM_LIB_URL
          ? window.MIXPANEL_CUSTOM_LIB_URL
          : 'file:' === e.location!.protocol && '//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\/\//)
          ? 'https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js'
          : '//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js';

      function load() {
        document.body.appendChild(script);
      }
      window.addEventListener('load', load, false);
    }
  })(document, window.mixpanel || []);
};

export const getMixpanelPathProps = () => ({
  Page: getPage(),
  'Path Short': getShortPath(),
  'Path Full': getFullPath()
});

type InitProps = {
  id: string;
  product?: AnimatronProduct;
  apiHost?: string;
};
export const init = ({ id, product = 'WAVE', apiHost }: InitProps) => {
  currentProduct = product;
  return getMixpanel()
    .then(mixpanel => mixpanel.init(id, { api_host: apiHost }))
    .catch(_ => {});
};

export const getUserEnv = async () => {
  await waitMixpanel();
  return getMixpanel()
    .then(mixpanel => {
      const { $device, $os, $browser } = mixpanel._.info.properties();
      const filteredProps = pickBy(Boolean)({
        $device,
        $os,
        $browser
      });

      const query = Object.keys(filteredProps)
        .map(key => `${key}=${filteredProps[key]}`)
        .join(';');

      const userEnv = encodeURI(query).replace(/\$/gi, '%24');

      return userEnv;
    })
    .catch(_ => {});
};

export const getMixpanelHeaders = async () => ({
  'anm-mkt-mix': window.mixpanel?.get_distinct_id?.(),
  'anm-mkt-userenv': await getUserEnv()
});

const createTrackAsync = () => {
  const trackPromiseQueue = { queue: Promise.resolve() };

  const trackAsync = (eventName: string, options?: {}) => {
    const promise = async () => {
      const mixpanel = await waitMixpanel();

      return new Promise<void>(resolve => {
        const mixpanelCallback = () => setTimeout(resolve, 500); // TODO setTimeout - fixed internally mixpanel problem with sequence track event

        mixpanel.track(eventName, options, mixpanelCallback);
      });
    };

    trackPromiseQueue.queue = trackPromiseQueue.queue.then(promise).catch(_ => {});
    return trackPromiseQueue;
  };

  return [trackAsync, trackPromiseQueue] as const;
};

const [trackAsync, trackPromiseQueue] = createTrackAsync();

export const waitForMixpanelFinish = () => trackPromiseQueue.queue;

export const track = async (eventName: string, options?: {}) => {
  await registerCommonProperties();
  return trackAsync(eventName, options);
};

export const getMixpanel = () =>
  !isServer() && window.mixpanel ? Promise.resolve(window.mixpanel) : Promise.reject('mixpanel not loaded');

const waitMixpanel = async () => {
  try {
    await waitFor(() => !!window.mixpanel?.cookie, 70, 'wait for mixpanel loaded');
  } catch (err) {
    console.warn(err);
  } finally {
    return window.mixpanel;
  }
};

export const getSource = () => {
  const referrer = document.referrer;
  if (referrer === '') return '';

  const referrerList = ['bing', 'quora', 'naver', 'yahoo', 'google', 'twitter', 'youtube', 'facebook', 'animatron'];

  const machedReferrer = referrerList.find(
    referrerItem => referrer.search(`https?://(.*)${referrerItem}.([^/?]*)`) === 0
  );

  return machedReferrer ? capitalize(machedReferrer) : referrer;
};

let commonPropsRegistered = false;
export const registerCommonProperties = (product = currentProduct) => {
  if (commonPropsRegistered) return Promise.resolve();

  const props = {
    Place: 'Website',
    Product: product
  };

  return getMixpanel()
    .then(mixpanel => {
      commonPropsRegistered = true;
      mixpanel.register(props);
    })
    .catch(_ => {});
};

const getUserTrackingData = ({
  email,
  meta: { product },
  username,
  displayName: name,
  subscriptionDetails
}: User): UserTrackingProps => {
  return {
    $name: name,
    $email: email,
    Username: username,
    Product: product,
    Source: getSource(),
    ...getSubscriptionPlans(subscriptionDetails)
  };
};

export const getSubscriptionPlans = (subscriptions: UserSubscriptionDetails[]) => {
  const getSub = (product: AnimatronProduct) => subscriptions.find(subscription => subscription.product === product);

  const waveSub = getSub('WAVE');
  const studioSub = getSub('STUDIO');

  return {
    'WAVE Plan': waveSub!.name,
    'STUDIO Plan': studioSub!.name,
    'WAVE Subscription': waveSub!.displayName,
    'STUDIO Subscription': studioSub!.displayName
  };
};

export const getUserInfoProps = ({ displayName, userIdAsString, username, email, subscriptionDetails }: User) => {
  const [firstName, lastName] = displayName.split(' ');

  const props = {
    ...getSubscriptionPlans(subscriptionDetails),
    $name: displayName,
    $email: email,
    $first_name: firstName,
    $last_name: lastName,
    userId: userIdAsString,
    Username: username
  };

  return pickBy(Boolean)(props);
};

export const registerUserProperties = (userProfile: User) => {
  const userProps = getUserTrackingData(userProfile);
  getMixpanel()
    .then(mixpanel => mixpanel.register(userProps))
    .catch(_ => {});
};

export const trackTemplate = (eventName: string, templateName: string, templateId: string) =>
  track(eventName, {
    ...getTemplateInfoObj(templateName, templateId)
  });

export const getTemplateInfoObj = (templateName: string, templateId: string) => ({
  Template: templateName,
  'Template Id': templateId
});
