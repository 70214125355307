import getShortPath from '../getShortPath';

const serviceRoutes = ['appsumo', 'activate', 'callback', 'confirmation', 'recovery'];

const getPage = () => {
  const page = getShortPath();
  const isService = serviceRoutes.some(route => page.match(route));

  return isService ? 'service' : page;
};

export default getPage;
