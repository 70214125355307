import compact from 'lodash/fp/compact';
export const toUrlParams = <T extends { [K in string]?: string | number | boolean | null | unknown }>(
  obj: T,
  isEncode = true
) =>
  Object.keys(obj).reduce((acc, k) => {
    const startLine = acc ? `${acc}&` : '';
    const value = obj[k];
    return typeof value !== undefined && value !== null
      ? `${startLine}${k}=${isEncode ? encodeURIComponent(value as string) : value}`
      : acc;
  }, '');

export const toObject = <T>(rawUrl = '', isDecode = false, separator = '&') =>
  !rawUrl
    ? ({} as T)
    : rawUrl
        .replace(/^([^?]+\?)|^#|\?/, '')
        .split(separator)
        .map(v => v.split(/^([^=]+)=/).filter(v => v))
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: isDecode ? decodeURIComponent(value) : value
          }),
          {} as T
        );

export const getParams = <T extends { [K in string]?: string | number | boolean | null }>(obj: T) =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    return {
      ...acc,
      [key]: value === '' || value === undefined ? true : value
    };
  }, {} as T);

export const composeUrlQuery = (...queries: string[]) => {
  const queriesWithoutEmpty = compact(queries);
  const composedQueries = queriesWithoutEmpty.join('&');

  return composedQueries && `?${composedQueries}`;
};
