import { FC } from 'react';
import styled from 'styled-components';

export const MODAL_NOTIFICATION_CONTAINER_ID = 'modal-notifications';

const ModalNotificationRootWrapper = styled.div`
  &:not(:empty) {
    position: fixed;
    margin-left: auto;
    padding-top: 95px;
    width: 100%;
    height: unset;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    overflow: visible;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: end;
    pointer-events: none;
  }
`;

const ModalNotificationRoot: FC = () => <ModalNotificationRootWrapper id={MODAL_NOTIFICATION_CONTAINER_ID} />;

export default ModalNotificationRoot;
