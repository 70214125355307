export const HEADER_HEIGHT = '74px';
export const HEADER_HEIGHT_MOBILE = '71px';
export const FOOTER_HEIGHT = '466px';
export const FOOTER_HEIGHT_MOBILE = '147px';
export const SMALL_FOOTER_HEIGHT = '94px';
export const DASHBOARD_NAVBAR_WIDTH = 284;
export const DASHBOARD_CONTENT_WRAPPER_WIDTH = 408;
export const CUSTOMIZATION_CONTENT_COLUMN_MAX_WIDTH = '438px';
export const INPUT_FIELD_HEIGHT = '50px';
export const PROJECT_PAGE_NAVBAR_WIDTH = '228px';
export const STREAMING_BACKDROP_BG = 'rgba(41, 42, 43, 0.6)';
export const LEFTBAR_TOP_PADDING = `30px`;
export const LEFTBAR_BOTTOM_PADDING = `34px`;
export const SHOW_MOBILE_HEADER_BREAKPOINT = 769;
export const MOBILE_NAVIGATION_DROPDOWN_HEIGHT = '70px';
export const MY_VIDEOS_HEADER_HEIGHT = '143px';
