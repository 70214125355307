import { DeepPartial } from 'helpers';

import defaultConfig from './default';
import { Config } from './types';
export * from './types';

const getConfig = (): DeepPartial<Config> =>
  process.env.VT_ENV === 'dev'
    ? require('./dev').default
    : process.env.VT_ENV === 'test'
    ? require('./test').default
    : require('./prod').default;

const envConfig = getConfig();
const lfConfig: Config = require('lodash/merge')(defaultConfig, envConfig);

export default lfConfig;
