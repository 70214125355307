import pick from 'lodash/fp/pick';

export const utmPropNames = [
  'utm_source' as const,
  'utm_medium' as const,
  'utm_campaign' as const,
  'utm_content' as const
];

const pickUTMParams = pick(utmPropNames);

export default pickUTMParams;
