const callAfterCall = <T extends any[]>(fn: (...arg: T) => Promise<any>) => {
  let trackPromiseQueue = Promise.resolve();

  return async (...arg: T) => {
    trackPromiseQueue = trackPromiseQueue.then(() => fn(...arg));
    return trackPromiseQueue;
  };
};

export default callAfterCall;
