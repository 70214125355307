// const debug = require('debug');

import {isUnitTest} from "@anm/shared/mode"

type logger = (...m: any) => void;

enum level {
  error,
  warning,
  info,
  debug
}

function format(fmt: string, args: any[]) {
  return args.reduce((p,c) => {
    const o = typeof c === 'object'? JSON.stringify(c): c?.toString();
    
    return p.replace(/%s/, o);
  }, fmt);
}

// @ts-ignore
function debug(ns: string, lvl: level) {
  let log: logger;
  switch (true) {
    case isUnitTest && lvl !== level.error:
      log = () => {};
      break;
    case lvl === level.warning:
      log = console.warn;
      break;
    case lvl === level.error:
      log = console.error;
      break;
    case lvl === level.info:
      log = console.info;
      break;
    default:
      log = console.debug;
      break;
  }
  
  return function (...data: any) {
    if (data.length===0) return;
    
    const isMsg = typeof data[0] === 'string'
    if (isMsg && (data[0].includes('%s') || data.length===1)) {
      log(`${ns} ${format(data[0], [...data].slice(1))}`);
    } else if (isMsg) {
      log(`${ns} ${data[0]}`, ...[...data].slice(1));
    } else {
      log(ns, ...data);
    }
  }
}

export class Logger {
  public static errorHandler: logger = () => {};

  readonly debug: logger;
  readonly info: logger;
  readonly warn: logger;
  readonly error: logger;

  constructor(readonly prefix?: string) {
    const APP_NAME = 'wave';
    const p = prefix ? `:${prefix}` : '';

    this.debug = debug(`[DEBUG]:${APP_NAME}${p}`, level.debug);
    this.info = debug(`[INFO]:${APP_NAME}${p}`, level.info);
    this.warn = debug(`[WARN]:${APP_NAME}${p}`, level.warning);

    const error = debug(`[ERROR]:${APP_NAME}${p}`, level.error);

    this.error = (...e) => {
      error(...e);
      Logger.errorHandler(...e);
    };
  }
}
