import { AnimatronProduct, AuthProviders } from 'global';

declare global {
  interface Window {
    fbq: any;
  }
}

const fb = (...arg: any[]) => window.fbq && window.fbq(...arg);

export const load = () => {
  /* tslint:disable */
  (function(f: any, b: any, e: any, v: any, n?: any, t?: any) {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.defer = !0;
    t.src = v;

    function l() {
      setTimeout(() => document.body.appendChild(t), 1500);
    }
    window.addEventListener('load', l, false);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );
};

export const init = (id: string) => {
  fb('init', id);
};

export const trackVisitPage = () => {
  fb('track', 'PageView');
};

export const trackSignUp = (
  product: AnimatronProduct,
  provider: AuthProviders
) => {
  fb('track', 'Sign Up', { Provider: provider, product: product });
};

export const addTransaction = (
  id: string,
  price: string | number,
  planName: string
) => {
  fb('track', 'Purchase', {
    id,
    planName,
    value: price,
    currency: 'USD'
  });
};
