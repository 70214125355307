const HOST_ID_LENGTH = 16;
const PROJECT_ID_LENGTH = 24;

const isId = (id?: string) => {
  if (!id) return false;

  const pattern = `[a-zA-Z\\d]`;
  const isMatchedByPattern = new RegExp(pattern).test(id);
  const isMatchedByLength = id.length === HOST_ID_LENGTH || id.length === PROJECT_ID_LENGTH;

  return isMatchedByPattern && isMatchedByLength;
};

export default isId;
