import { STATIC_DEV_URL, STATIC_PROD_URL, STATIC_TEST_URL } from '@anm/constants/urls';

const globalCdnUrl = (() => {
  switch (process.env['STATIC_ENV']) {
    case 'dev':
      return STATIC_DEV_URL;
    case 'test':
      return STATIC_TEST_URL;
    case 'prod':
      return STATIC_PROD_URL;
    default:
      throw new Error('Missed environment: STATIC_ENV');
  }
})();

const getFromCdn = (src: string, cdnUrl?: string) => (cdnUrl ? `${cdnUrl}static/${src}` : `${globalCdnUrl}${src}`);

export default getFromCdn;
