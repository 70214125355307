import { User } from 'user';

declare global {
  interface Window {
    FS: {
      identify(uid: string, userVars: {}): void;
      identify(isUser: boolean): void;
    };
    _fs_debug: any;
    _fs_host: any;
    _fs_org: any;
    _fs_namespace: any;
  }
}

export const load = () => {
  /* tslint:disable */

  (function(
    m: any,
    n: any,
    e: any,
    t: any,
    l: any,
    o: any = null,
    g: any = null
  ) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log(
          'FullStory namespace conflict. Please set window["_fs_namespace"].'
        );
      }
      return;
    }
    g = m[e] = function(a: any, b: any) {
      g.q ? g.q.push([a, b]) : g._api(a, b);
    };
    g.q = [];
    o = n.createElement(t);
    o.defer = 1;
    o.src = 'https://' + window._fs_host + '/s/fs.js';

    function load() {
      setTimeout(() => document.body.appendChild(o), 1500);
    }
    window.addEventListener('load', load, false);

    g.identify = function(i: any, v: any) {
      g(l, { uid: i });
      if (v) g(l, v);
    };
    g.setUserVars = function(v: any) {
      g(l, v);
    };
    g.event = function(i: any, v: any) {
      g('event', { n: i, p: v });
    };
    g.shutdown = function() {
      g('rec', !1);
    };
    g.restart = function() {
      g('rec', !0);
    };
    g.consent = function(a: any) {
      g('consent', !arguments.length || a);
    };
    g.identifyAccount = function(i: any, v: any) {
      o = 'account';
      v = v || {};
      v.acctId = i;
      g(o, v);
    };
    g.clearUserCookie = function() {};
  })(window, document, window['_fs_namespace'], 'script', 'user');
};

export const init = (id: string) => {
  window['_fs_debug'] = false;
  window['_fs_host'] = 'fullstory.com';
  window['_fs_org'] = id;
  window['_fs_namespace'] = 'FS';
};

export const setUser = (user: User | null) => {
  if (!window.FS) return;

  if (user) {
    const { email, displayName } = user;
    window.FS.identify(user.userIdAsString, { email, displayName });
  } else {
    window.FS.identify(false);
  }
};
