export const ANIMATRON_PROD = 'https://www.animatron.com/';
export const ANIMATRON_API_PROD = 'https://api.animatron.com/';
export const ANIMATRON_ASSETS_PROD = 'https://assets.animatron.com/';
export const ANIMATRON_LIBRARY_PROD = 'https://library.animatron.io/';

export const WAVE_DEV = `http://localhost:3005/`;
export const WAVE_PROD = 'https://wave.video/';
export const WAVE_API_PROD = 'https://api.wave.video/';
export const WAVE_WATCH_PROD = 'https://watch.wave.video/';
export const WAVE_EMBED_PROD = 'https://embed.wave.video/';
export const WEBINAR_PROD = 'https://webinar.mywave.video/';
export const YOUTUBE_API_PROD = 'https://youtube-dl.wave.video';

export const ANIMATRON_TEST = 'https://www.animatron-test.com/';
export const ANIMATRON_API_TEST = 'https://api.animatron-test.com/';
export const ANIMATRON_ASSETS_TEST = 'https://assets.animatron-test.com/';
export const ANIMATRON_LIBRARY_TEST = 'https://animatron-test-library.s3.amazonaws.com/';

export const WAVE_TEST = 'https://wave-test.video/';
export const WAVE_API_TEST = 'https://api.wave-test.video/';
export const WAVE_WATCH_TEST = 'https://watch.wave-test.video/';
export const WAVE_EMBED_TEST = 'https://embed.wave-test.video/';
export const WEBINAR_TEST = 'https://webinar-test.mywave.video/';
export const YOUTUBE_API_TEST = 'https://youtube-dl.test.stacks.animatron-test.com';

export const PLAYER_ANALYTICS_DEV = 'http://localhost:9002/';
export const PLAYER_ANALYTICS_TEST = 'https://ingest.test.stacks.animatron-test.com/';
export const PLAYER_ANALYTICS_PROD = 'https://ingest.animatron.com/';

export const BUNNY_CDN_1_TEST = 'https://cdn1-test.mywave.video/';
export const BUNNY_CDN_2_TEST = 'https://cdn2-test.mywave.video/';

export const BUNNY_CDN_1_PROD = 'https://cdn1.mywave.video/';
export const BUNNY_CDN_2_PROD = 'https://cdn2.mywave.video/';

export const GIPHY = 'https://d2bfg3hktrwrnd.cloudfront.net/v1/stickers';

export const SEMRUSH_TEST = `https://www.semrush.com/app/wave-video-test/`;
export const SEMRUSH_PROD = `https://www.semrush.com/app/wave-video/`;
