import isServer from '../is/isServer';

const storage = new Map<string, string>();

const localStorageMock: Storage = {
  setItem(key, value) {
    storage.set(key, value);
  },

  getItem(key) {
    return storage.get(key) || null;
  },
  removeItem(key) {
    storage.delete(key);
  },

  get length() {
    return storage.size;
  },

  key(i) {
    const keys = [...storage.keys()];
    return keys[i] || null;
  },

  clear() {
    storage.clear();
  }
};

let errorShown = false;

const localStorage = (() => {
  if (isServer()) {
    return () => {
      throw new Error('localStorage can not be used on server-side');
    };
  }

  try {
    return window.localStorage ? () => window.localStorage : () => localStorageMock;
  } catch (e) {
    if (!errorShown) {
      console.error('localStorage access error: ', e);
      errorShown = true;
    }

    return () => localStorageMock;
  }
})();

export default localStorage;
